.tabPanel{
    background: #E1E1E1;
    border-radius: 10px;
}

/* .viewpackage-tabbed-maincontainer{
} */

.viewpackage-tabs-left-container{
    padding: 50px;
}

.viewpackage-tabs-left-container p{
    font-style: italic;
}

.viewpackage-tabs-right-container{
    padding: 50px;
}

.MuiTabs-indicator{
    display: none;
}

.Mui-selected{
    font-weight: 800 !important;
    color: #000 !important;
}

@media screen and (max-width: 700px){
    .viewpackage-tabbed-maincontainer {
        flex-direction: column;
    }
    .viewpackage-tabs-left-container{
        width: 100%;
        padding: 20px;
    }
    .viewpackage-tabs-right-container{
        width: 100%;
        padding: 20px;
    }
    .viewpackageCategory{
        flex-direction: column;
    }
}
