
.viewpackage-hero-container{
    width: 100%;
}

.ViewPackage-PackageTitle{
    margin-top: 100px;
    margin-bottom: 30px;
}

.ViewPackage-PackageDescription{
    background-color: #E1E1E1;
    padding: 50px;
    height: fit-content;
    border-radius: 10px;
    position: relative;
    display: flex;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.viewpackage-description-right{
    padding-right: 100px;
}

.viewpackage-description-right h4{
    margin-bottom: 20px;
    font-weight: 600;
}

.viewpackage-description-right p{
    font-style: italic;
    font-weight: 400;
}

.viewpackage-rating-container{
    padding-top: 10px;
}

.viewpackage-description-left{
    width: 430px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;
    margin-top: 25px;
}

.viewpackage-description-left-slider{
    position: absolute;
    top: 200px;
}

.bookbtn{
    width: 200px;
    border-radius: 5px;
    background: linear-gradient(180deg, #FFC06C 0%, #DC922F 100%);
    padding: 5px;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.22) 0px 10px 10px;
    position: relative;
    top: -50px;
    left: -50px;
}

.viewpackage-multiple-slider-container{
    width: calc(100%);
    position: relative;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-bottom: 100px;
}

@media screen and (max-width: 1366px){
    .viewpackage-description-right{
        padding-right: 0px;
    }
}

@media screen and (max-width: 700px){
    .ViewPackage-PackageDescription{
        flex-direction: column;
        padding: 10px;
    }
    .viewpackage-description-left{
        width: 300px;
    }
    .viewpackage-description-right{
        width: 100%;
        padding: 100px 20px 0px;
    }
    .bookbtn{
        top: 30px;
        left: 0;
    }
    .PackageTitle-wrapper{
        width: 100%;
    }
    .ViewPackage-PackageTitle{
        margin-top: 50px;
        flex-direction: column;
    }
}