.slider {
    position: relative;
    width: 100%;
}
.images {
    display: flex;
    width: 100%;
}
.images img {
    height: 400px;
    width: 100%;
    transition: 0.89s ease-in;
}
.images input {
    display: none;
}
.dots {
    display: flex;
    justify-content: center;
    margin: 5px;
    z-index: 1;
}
.dots label {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    border: solid #256eff 2px;
    cursor: pointer;
    transition: all 0.15s ease;
    margin: 5px;
}
.dots label:hover {background: #fff;}
#img1:checked ~ .m1 {
    margin-left: 0;
}
#img2:checked ~ .m2 {
    margin-left: -100%;
}
#img3:checked ~ .m3 {
    margin-left: -200%;
}
#img4:checked ~ .m4 {
    margin-left: -300%;
}