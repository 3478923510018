.sidebar{
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 15%; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 30px; /* Stay at the top */
  left: 0;
  background-color: rgb(255, 255, 255); /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px;
  box-shadow: 2px 2px 5px #7a7a7a70;
}

.sidebar::-webkit-scrollbar{
    width: 2px;
    height: 10px;
  }

.sidebar::-webkit-scrollbar-track {
  background: #ebebeb; 
}

.sidebar::-webkit-scrollbar-thumb {
    background: #888; 
}

.sidebar ::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.sidebarWrapper{
    padding-top: 20px;
    color: #555;
    position: absolute;
    width: 100%;
}

.sidebarMenu{
    margin-bottom: 10px;
}

.sidebarTitle{
    font-size: 13px;
    color: rgb(187, 186, 186);
}

.sidebarList{
    list-style: none;
}

.sidebarListItem {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 12px;
  }

.sidebarLink{
    text-decoration: none;
    color: #555;
}

.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: rgb(240, 240, 255);
  border-radius: 25px 0 0 25px;
}

.sidebarIcon{
    margin-right: 5px;
    font-size: 20px !important;
}

.expand_icon{
    font-size: 10px;
}