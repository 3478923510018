.contact-form{
    position: relative;
    background-color: rgb(238, 238, 238);
    padding:50px;
    border-radius: 25px;
    /* width: 100%; */
    display: inline-block;
}

.contact-form table{
    width: auto;
}

.contact-form table tr td{
    display: flex;
    flex-direction: column;
    /* background-color: rgb(238, 238, 238);     */
    color: #797878;
    /* padding: 20px; */
}

.contact-form input {
    width: 100%;
    height: 40px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    color: #8e8e8e;
    /* padding: 5px; */
}

.contact-form input[type=submit]{
    margin-top: 30px;
    padding: 10px;
    font-weight: bold;
    color: #fff;
    background-color: rgb(75, 75, 75);
    border:none ;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
}

.contact-form table td{
    font-weight: bold;
    padding: 5px;
}