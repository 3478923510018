body{
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    /* -webkit-user-select: none; */
    overflow-x: hidden;
}
.header-container{
    position: relative;
    background-color: rgb(255, 255, 255);
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5px;
}

.logo img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 15%;
}

.menuitem{
    padding-top: 15px;
    text-align: center;
}

.menuitem ul li{
    display: inline;
    padding: 5px;
    font-weight: bold;
    color: rgb(92, 92, 92);
}


.menuitem ul li :hover{
    font-weight: bold;
    color: rgb(92, 92, 92);
    border-bottom: 2px solid rgb(92, 92, 92);
    cursor: pointer;
}

.menuitem ul li :active{
    font-weight: bold;
    color: rgb(92, 92, 92);
    border-bottom: 2px solid rgb(92, 92, 92);
    cursor: pointer;
}

.menuitem .navmenu{
    color: rgb(92, 92, 92);    
    text-decoration: none;
}

.is-active{
    color: rgb(92, 92, 92);
    text-decoration: none;
    border-bottom: 2px solid rgb(92, 92, 92);
}

.hamburger{
    display: none;
}

  @media only screen and (max-width: 481px){
    .logo img{
        display: block;
    margin-left: auto;
    margin-right: auto;
    width: 15%;
    }
    .hamburger{
        padding: 30px;
        display:block;
        align-items:flex-end;
        justify-content: center;
    }

    .menuitem{
        
        display: none;

    }

    .menuitem-active ul li{
        padding: 5px;
        font-weight: bold;
        color: rgb(92, 92, 92);
        text-align: center;
    }
    
    .menuitem-active .navmenu{
        text-decoration: none;
        color: rgb(92, 92, 92);
    }
  }

  @media only screen and (max-width: 480px){
    .logo img{
        position: absolute;
        left: 23%;
        width: 200px;
    }
    .hamburger{
        padding: 20px 30px 30px;
        display:block;
        align-items:flex-end;
        justify-content: center;
    }

    .menuitem{
        
        display: none;

    }

    .menuitem-active ul li{
        padding: 5px;
        font-weight: bold;
        color: rgb(92, 92, 92);
        text-align: center;
    }
    
    .menuitem-active .navmenu{
        text-decoration: none;
        color: rgb(92, 92, 92);
    }
  }

