
.slider-main-container{
    width: 100%;
}
.slider-content{
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mySwiper{
    width: 520px;
}

.SliderPackageImage{
    width: 160px;
    height: 180px;
    position: relative;
    top: 110px;
    left: -67px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.SliderPackageImage img{
    border-radius: 10px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.SliderPackageDetails{
    width: 380px;
    height: 400px;
    display: flex;
    align-items: center;
    background-color: #1E1E1E;
    border-radius: 10px;
    color: #fff;
    padding-top: 95px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.SliderPackageDetails p{
    font-size: 13px;
    font-style: italic;
    font-weight: 300;
}
.SliderPackageDetails .packagename{
    font-size: 20px;
    width: 200px;
    font-weight: 600;
}

.swiper-button-prev {
    color: #1E1E1E;
}
.swiper-button-next {
    color: #1E1E1E;
}
.swiper-button-prev::after {
    font-size: 30px;
    font-weight: 900;
}
.swiper-button-next::after {
    font-size: 30px;
    font-weight: 900;
}

@media screen and (max-width: 800px){
    .mySwiper{
        width: 371px;
    }
    .SliderPackageDetails{
        width: 280px;
    }
    .SliderPackageImage{
        width: 120px;
        left: -52px;
    }
}