@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

.topbar{
    width: 100%;
    height: 50px;
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 999;
    box-shadow: 2px 2px 5px #7a7a7a70;
}

.topbar_wrapper{
    height: 100%;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topbar_logo{
    width: 45%;
    height: auto;
}

.topbar_avatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.topbar_avartar_name{
    font-size: 20px;
    position: absolute;
    top: 15px;
    right: 60px;
    font-family: montserrat;
    text-transform: capitalize;
    cursor: pointer;
}