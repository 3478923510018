@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: Poppins;
}

.main-section{
    margin-bottom: 100px;
    padding: 0;
    background-color: rgb(251, 251, 255);
}

.packagelist-container{
    max-width: 1100px;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    padding: 10px;
    background-color: rgb(238 238 238 / 32%);
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
}

.packagelist-left img{
    max-width: 350px;
    min-height: 250px;
    background-size: conatin;
}

.packagelist-right{
    padding-left: 30px;
    padding-top: 10px;
}

.packagelist-right p{
    display: flex;
}

.product-price{
    display: block;
    padding-top: 15px;
}

.product-price span{
    font-weight: 400;
}
.last-price span{
    color: #f64749;
    text-decoration: line-through;
}
.new-price span{
    color: #256eff;
}

.details-btn{
    background: #ffc06c;
    color: #fff;
    padding: 10px;
    text-decoration: none;
    border-radius: 5px;
}
