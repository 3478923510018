
.wrapper{
    display: flex;
}

.col-sidebar{
    flex: 1;
}

.col-main{
    flex: 4;
    position: relative;
    margin-top: 5%;
    width: 100%;
    height: 100%;
}