.section-margin{
    margin-top: 120px;
    margin-bottom: 100px;
}
.col-gap{
    gap: 2rem;
}

.package-details{
    height: fit-content;
    width: 100%;
    background-color: #363636;
    border-radius: 10px;
    color: #fff;
    padding-left: 95px;
    padding-right: 10px;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
.package-image{
    width: 112px;
    height: 151px;
    border-radius: 10px;
    position: relative;
    left: 80px;
    top: 30px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.package-image img{
    border-radius: 10px;
    height: 100%;
    object-fit: cover;
}
.packagename{
    font-size: 20px;
    width: 200px;
}
.package-details p{
    font-size: 10px;
    font-style: italic;
    font-weight: 100;
}
.explorebtn{
    border-radius: 5px;
    background: linear-gradient(180deg, #FFC06C 0%, #DC922F 100%);
    padding: 5px;
    margin-top: 15px;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.destinationContainer{
    margin-top: 100px;
}
.destinationPackage{
    height: fit-content;
    background-color: transparent;
}
.destinationPackageImage{
    width: 112px;
    height: 151px;
    position: relative;
    top: 110px;
    left: -67px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.destinationPackageImage img{
    border-radius: 10px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.destinationPackageDetails{
    width: 315px;
    height: 350px;
    display: flex;
    align-items: center;
    background-color: #E1E1E1;
    border-radius: 10px;
    color: #000;
    padding-top: 95px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.destinationPackageDetails p{
    font-size: 13px;
    font-style: italic;
    font-weight: 500;
}
.destinationPackageDetails .packagename{
    font-size: 20px;
    width: 200px;
    font-weight: 800;
}

@media screen and (max-width: 1366px){
    .col-4{
        width: 31.33%;
    }
    .destinationPackageDetails{
        width: 250px;
        height: 290px;
        padding-top: 117px;
    }
    .destinationPackageImage{
        left: -40px;
    }
    .packagename{
        font-size: 17px !important;
    }
    .destinationPackageDetails p{
        font-size: 9px !important;
    }
    .explorebtn{
        width: 100px;
        height: 30px;
        font-size: 10px;
        
    }
}
@media screen and (max-width: 700px){
    .packageList-slider{
        flex-direction: column;
    }
    .packagelistLeftImage{
        width: 100%;
    }
    .packagelistLeftImage img{
        width: 100%;
        position: relative;
    }
    .packagelistRightSlider{
        width: 100%;
    }
    .packageList-slider-container{
        padding: 10px;
    }
}
