.body-container{
    width: 100%;
    padding: 20px;
    margin: 0;
    background: url(./yatras-banner\ 1.png);
    background-size: cover;
    min-height: 100vh;
    background-position: center;
    background-attachment: fixed;
}

.booking-form-wrapper{
    background-color: #fff;
    opacity: .7;
    padding: 20px;
    width: 800px;
}

.width80{
    width: 80% !important;
}

.width20{
    width: 20% !important;
}