.viewpackage-slider-main-container{
    /* width: 100%; */
    position: absolute;
    top: -190px;
    left: 50px;
}

.mySingleSwiper{
    justify-content: center;
    width: 430px;
    border-radius: 10px;
}

.Slider-container{
    width: 420px;
    height: 500px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 15px;
}

.SingleSLiderImg{
    width: fit-content;
    height: 100%;
    border-radius: 10px;
}

@media screen and (max-width: 700px){
    .viewpackage-slider-main-container{
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
    .mySingleSwiper{
        width: 100%;
        height: 400px;
    }
    .SingleSLiderImg{
        width: 100%;
    }
    
}
