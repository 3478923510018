html, body {
    overflow-x: hidden;
  }
  body {
    position: relative
  }


.section:nth-child(odd){
    position: relative;
    display:flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    /* border-bottom: 1px solid red; */
    background-color: rgb(163, 163, 163);
}

.section:nth-child(even){
    position: relative;
    display:flex;
    justify-content: center;
    align-items: center;
    color: black;
    /* border-bottom: 1px solid red; */
    background-color: rgb(255, 255, 255);
}

.section div{
    /* border: 1px solid #000; */
    /* position: relative; */
    /* width: auto; */
    margin: 5%;
    text-align: left;
}



.section img {
    /* position: relative; */
    /* padding: 5px; */
    max-width: auto;
    /* justify-content: left; */
}

.section-img{
    max-height: 10%;
}

.section h1{
    font-size: 3rem;
}

.section .description{
    font-size: 20px;
    font-style: italic;
    font-weight: lighter;
}

.section .logo{
    text-align: center;
    right: 40%;
}

.section .logo img{
    max-width: 100%;
    height: auto;
}

.section .contact-info{
    display: flex;
    flex-direction: column;
    /* margin-right: 200px; */
    color: rgb(255, 255, 255);
    /* font-weight: bold; */
    font-size: 30px;
    padding: 100px;
    width: 100%;
}

.section .contact-info span,i{
    padding: 10px;
}

.section h2 span{
    color: rgb(228, 228, 228);
}

@media screen and (max-width: 480px) {
    .section:nth-child(odd){
        max-width: auto;
    }
    /* .section img {
        max-height: 10%;
        max-width: auto;
    } */

    .section .contact-info{

        font-size:small;
    }


}