@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');

/* .newUser {
    flex: 4;
  }

  .newUserTitle{
    font-family: montserrat;
  }

  .newUserTitle2{
    margin-top: 50px;
    font-family: montserrat;
  }

  .newUserForm {
    display: block;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-bottom: 50px;
  }
  
  .newUserItem {
    width: 300px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 20px;
  }
  
  .newUserItem > label {
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: 600;
    color: rgb(151, 150, 150);
    font-family: montserrat;
  }
  
  .newUserItem > input {
    height: 30px;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
  }

  .newUserItem > input::placeholder{
    font-family: montserrat;
  }
  
  select .newUserSelect{
      height: 30px;
      border-radius: 5px;
  }

  select{
    font-family: montserrat;
  }

  option{
    font-family: montserrat;
  }
  
  .newUserButton{
      width: 200px;
      border: none;
      background-color: #3bb077;
      color: white;
      padding: 7px 10px;
      font-weight: 600;
      border-radius: 10px;
      margin-top: 30px;
      cursor: pointer;
  }

.PlanningBenifits{
  display: flex;
  margin-top: 40px;
}

.BenifitLabel{
  font-family: montserrat;
  font-size: 17px;
  padding-bottom: 10px;
  color: rgb(151, 150, 150);
  font-weight: 600;
}

.TravelBenifits{
  display: block;
  margin-right: 150px;
}

.DescriptionTextEditor{
  margin-top: 40px;
  width: 900px;
} */

/* Customize the label (the container) */
/* .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  font-family: montserrat;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
} */

/* Hide the browser's default checkbox */
/* .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
} */

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}