.MultipleSLiderImg{
    width: 221px;
    height: 100%;
    border-radius: 10px;
}

.myMultipleSwiper{
    background-color: #E1E1E1;
    border-radius: 10px;
}

.MultipleSlider-container{
    height: 333px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 30px;
}