.HomeSwiper{
    display: flex;
    justify-content: center;
    width: 100%;
}

.HomeSlider-container{
    width: 320px;
    height: 600px;
    position: relative;
    display: flex;
    justify-content: center;
    object-fit: cover;
    margin: 0 30px 0 0!important;
}

.HomeSingleSLiderImg{
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    cursor: pointer;
}

.HomesliderContent{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;
    font-size: 20px;
    font-style: italic;
    font-weight: lighter;
    color: aliceblue;
}

/* 
.slider-overlay{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.75);
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
    margin: 0 !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.slider-overlay h2{
    position: absolute;
    text-align: center;
    width: 100%;
    margin-top: 30px;
}

.HomeSlider-container:hover .slider-overlay{
    height: 20%;
} */

@media screen and (max-width: 700px){
    .homeSwiperContainer{
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
    .HomeSwiper{
        width: 90%;
        height: fit-content;
    }
    .HomeSingleSLiderImg{
        width: 100%;
    }
    .HomeSlider-container:hover .slider-overlay{
        height: 25%;
    }
    .HomesliderContent{
        height: fit-content;
    }
}
