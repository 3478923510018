.homePageSliderEdit_Section {
    padding: 2rem 0;
  }
  
  .homePageSliderEdit_Label {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dotted black;
    border-radius: 20px;
    width: 10rem;
    height: 10rem;
    cursor: pointer;
    font-size: large;
  }
  
  .homePageSliderEdit_Label span {
    font-weight: lighter;
    font-size: small;
    padding-top: 0.5rem;
  }
  
  .homePageSliderEdit_Input{
    display: none;
  }
  
  .homePageSliderEdit_SingleImage_img {
    padding: 0;
    margin: 0;
  }
  
  .homePageSliderEdit_Images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .homePageSliderEdit_SingleImage {
    margin: 1rem 0.5rem;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }
  
  .homePageSliderEdit_SingleImage button {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    border: none;
    color: white;
    background-color: lightcoral;
  }
  
  .homePageSliderEdit_SingleImage button:hover {
    background-color: red;
  }
  
  .homePageSliderEdit_SingleImage p {
    padding: 0 0.5rem;
    margin: 0;
  }
  
  .upload-btn {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    border: none;
    border-radius: 20px;
    width: 10rem;
    height: 3rem;
    color: white;
    background-color: #ffc06c;
  }
  
  .homePageSliderEdit_Label:hover,
  .upload-btn:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }
  
  .error {
    text-align: center;
  }
  
  .error span {
    color: red;
  }