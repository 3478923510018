.form {
    position: relative;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: rgb(238, 238, 238);
    /* background-color: rgb(206, 99, 99); */
    color: #000;
    padding: 30px;
    width: auto;
    /* background-color: yellow; */
    display: inline-block;
}

.form label{
    font-weight: bold;
    font-size: 1.2rem;
}

.form table{
    /* background-color: green; */
    width: 100%;
    /* border: 1px solid black; */
}

.form table td{
    /* border: 1px solid black; */
    padding: 5px; 
    /* width: 10px; */
    
}

.form input[type=date]{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 100%;
    height: 50px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    color: #8e8e8e;
    padding: 5px;
}

.form select{
    width: 100%;
    height: 50px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    color: #8e8e8e;
    padding: 5px;
}

.form input[type=submit]{    
    margin: 10px;
    padding: 10px;
    font-weight: bold;
    color: #fff;
    background-color: rgb(75, 75, 75);
    border:none ;
    border-radius: 5px;
    width: 100px;
    cursor: pointer;
}
