.yathra-form-input{
    width: calc(100% - 100px) !important;
    margin-bottom: 30px;
}
.yathraform label{
    font-weight: 500;
}
.nav-pills .yathra-select{
    font-weight: 500;
}
.nav-pills .yathra-select.active{
    background-color: grey !important;
    font-weight: 600;
}

button{
    border: none;
    color: rgb(88, 88, 88);
    background-color: #ffc06c;
    padding: 5px;
}

button:hover{
    background-color: #ffc107;
}

.fit_image{
    object-fit: cover;
    margin: 20px;
}

.edit_btn{
    background-color: dodgerblue;
    color: #ffffff;
    border-radius: 10px;
    padding: 5px 15px;
    margin: 0 10px;
}

.edit_btn:hover{
    background-color: rgb(25, 121, 218);
}

.delete_btn{
    background-color:crimson;
    color: #ffffff;
    border-radius: 10px;
    padding: 5px 15px;
    margin: 0 10px;
}

.delete_btn:hover{
    background-color: rgb(187, 17, 51);
}

.icon{
    margin: 5px;
}