.gallery_MainSection{
    column-width: 300px;
    column-gap: 5px;
    row-gap: 5px;
    padding: 50px;
}

.gallery_MainSection img{
    width: 100%;
    cursor: pointer; 
}