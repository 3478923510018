.charts{
    width: 70%;
    top: 20%;
    justify-content: center;
    align-items: center;
    margin: 20px;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.chartTitle{
    margin-bottom: 40px;
}